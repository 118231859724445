/* achievement */

.achievement__container {
  grid-template-columns: repeat(3, 270px);
  justify-content: center;
  column-gap: 1.8rem;
}

.achievement__content {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: var(--container-color);
  padding: 2rem 0 2rem 2.5rem;
  border-radius: 1.25rem;
  cursor: pointer;
}

.achievement__icon {
  display: block;
  font-size: 2rem;
  color: var(--title-color);
  margin-bottom: var(--mb-1);
}

.achievement__title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-0-5);
  font-weight: var(--font-medium);
  font-family: var(--font-poppins);
}

.achievement__description {
  font-size: var(--tiny-font-size);
  margin-bottom: var(--mb-0-5);
  font-weight: 200;
  color: var(--text-color);
}

.achievement__button {
  color: var(--title-color);
  font-size: var(--small-font-size);
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  cursor: pointer;
}

.achievement__button-icon {
  font-size: 1rem;
  transition: 0.3s;
}

.achievement__button:hover .achievement__button-icon {
  transform: translateX(00.25rem);
}

/* For large devices */
@media screen and (max-width: 992px) {
  .achievement__container {
    grid-template-columns: repeat(3, 218px);
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .achievement__container {
    grid-template-columns: repeat(2, 1fr);
  }

  .achievement__content {
    padding: 3.5rem 0.5rem 1.25rem 1.5rem;
  }

  .achievement__title {
    font-size: var(--h6-font-size);
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .achievement__container {
    grid-template-columns: max-content;
  }
}
