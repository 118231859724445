/* achievement */

.project .achievement__container {
  grid-template-columns: repeat(2, 400px);
  justify-content: center;
  column-gap: 1.8rem;
}

.project .achievement__content {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: var(--container-color);
  padding: 1.2rem;
  border-radius: 1.25rem;
  cursor: pointer;
}

.project .achievement__icon {
  display: block;
  font-size: 2rem;
  color: var(--title-color);
  margin-bottom: var(--mb-1);
}

.project .achievement__title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  font-family: var(--font-poppins);
  text-align: center;
}

.project .achievement__icon {
  /* width: 50px; */
  /* height: 130px; */
  /* object-fit: cover; */
  justify-self: center;
  border-radius: 20px;
  padding: 10px;
  width: 100%;
}

.project .achievement__description {
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-0-5);
  text-align: left;
  padding: 0px 15px;
  font-weight: 200;
}

.project .achievement__tag {
  color: #ff592e;
  font-size: var(--tiny-font-size);
  text-align: center;
  margin-bottom: var(--mb-0-5);
}

.project .achievement__button {
  color: var(--title-color);
  font-size: var(--small-font-size);
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  cursor: pointer;
}

.project .achievement__button-icon {
  font-size: 1rem;
  transition: 0.3s;
}

.project .achievement__button:hover .achievement__button-icon {
  transform: translateX(00.25rem);
}

.project__button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--mb-3);
}
/* For large devices */
@media screen and (max-width: 992px) {
  .project .achievement__container {
    grid-template-columns: repeat(2, 250px);
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .project .achievement__container {
    grid-template-columns: 1fr;
  }

  .project .achievement__content {
    padding: 20px;
  }

  .project .achievement__title {
    font-size: var(--h6-font-size);
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .project .achievement__container {
    grid-template-columns: 1fr;
  }
}
